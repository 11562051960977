<template>
  <b-row>
    <b-col md="3">
      <b-card title="Filters">
        <form @submit.prevent>
          <b-form-group label="Date Range">
            <flat-pickr
              v-model="dateRange"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
                allowInput: true,
                defaultDate: ['2023-01-01', '2023-01-10'],
              }"
              class="form-control"
            />
          </b-form-group>
          <b-form-group label="Project">
            <v-select
              v-model="project"
              :options="projects"
              label="name"
              :reduce="(project) => project.id"
            />
          </b-form-group>
          <b-form-group label="Assigned To">
            <v-select
              v-model="assignedTo"
              :options="sortedUsers"
              label="name"
              :reduce="(user) => user.id"
            />
          </b-form-group>
          <b-form-group label="Assigned By">
            <v-select
              v-model="assignedBy"
              :options="sortedUsers"
              label="name"
              :reduce="(user) => user.id"
            />
          </b-form-group>
          <b-form-group label="Status">
            <v-select
              v-model="fStatus"
              :options="statuses"
              label="name"
              :reduce="(status) => status.value"
            />
          </b-form-group>
          <div class="d-flex justify-content-between align-items-center">
            <b-button variant="primary" size="sm" @click="applyFilter()">
              <feather-icon icon="CheckIcon" /> Apply
            </b-button>
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="resetFilter()"
            >
              <feather-icon icon="RefreshCwIcon" /> Reset
            </b-button>
          </div>
        </form>
      </b-card>
    </b-col>
    <b-col md="9">
      <b-card-actions
        title="Tasks"
        action-refresh
        @refresh="onRefresh"
        ref="mainCard"
      >
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div class="d-flex align-items-center">
            <label for="" class="mr-1" style="font-size: 14px">Sort</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-100"
            />
            <download-excel
              :data="exportItems"
              :fields="exportFields"
              :filename="'Employee'"
              :sheetname="'Employee'"
              v-if="getRole() != 'staff'"
              class="cursor-pointer ml-1"
            >
              <b-button
                variant="outline-secondary"
                size="sm"
                class="d-flex mr-1 export-btn"
              >
                <feather-icon icon="FileIcon" size="14" class="text-success" />
                <label
                  style="font-size: 14px; margin-left: 5px; cursor: pointer"
                  >Export</label
                >
              </b-button>
            </download-excel>
          </div>
          <div class="d-flex align-items-center">
            <label for="" class="mr-1" style="font-size: 14px">Search</label>
            <b-form-input
              id="searchInput"
              v-model="filter"
              size="sm"
              type="search"
              placeholder="Search"
            />
          </div>
        </div>
        <b-row>
          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              @filtered="onFiltered"
              class="header-wrap"
            >
              <template #cell(name)="data">
                <span
                  v-b-toggle="`sidebar_${data.value.id}`"
                  @click="setTaskId(data.value.id)"
                  class="text-primary"
                >
                  {{ data.value.name }}
                </span>
              </template>

              <!-- <template #cell(profile_photo_url)="data">
                <b-avatar-group size="32px">
                  <b-avatar
                    v-for="user in data.value.profile_photo_url"
                    :key="user.id"
                    v-b-tooltip.hover
                    class="pull-up"
                    :title="user.name"
                    :src="user.profile_photo_path"
                  />
                </b-avatar-group>
              </template> -->

              <template #cell(status)="data">
                <!-- <b-badge :variant="status[1][data.value]">
                  {{ status[0][data.value] }}
                </b-badge> -->
                <b-dropdown
                  :text="status[0][data.value.status]"
                  variant="outline-primary"
                  size="sm"
                  class="text-capitalize"
                >
                  <b-dropdown-item
                    v-for="item in status[0]"
                    :key="item"
                    :variant="status[1][item]"
                    @click="updateStatus(item, data.value.id)"
                  >
                    {{ item }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <template #cell(actions)="data">
                <div
                  class="text-center"
                  v-if="data.value.created_by == getAuthId()"
                >
                  <feather-icon
                    v-b-modal="`detail_task_${data.value.id}`"
                    icon="EditIcon"
                    size="20"
                    class="text-primary"
                  ></feather-icon>
                </div>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center">
              <span>
                Showing
                {{ perPage > totalRows ? totalRows : perPage }} of
                {{ totalRows }} entries
              </span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="sm"
                class="my-0"
              />
            </div>
          </b-col>
        </b-row>
      </b-card-actions>
    </b-col>

    <b-sidebar
      v-for="item in items"
      :key="item.id"
      :id="`sidebar_${item.id}`"
      shadow
      right
      bg-variant="white"
      backdrop
      no-header
      :ref="`sidebar_${item.id}`"
    >
      <div
        class="d-flex justify-content-between align-items-center bg-secondary"
        style="padding: 15px 20px"
      >
        <h5 class="text-light">Detail</h5>
        <span class="text-light cursor-pointer" @click="hideSidebar(item.id)">
          &#10006;
        </span>
      </div>
      <div>
        <b-row>
          <b-col md="9" class="left-area">
            <!-- <div class="d-flex justify-content-between align-items-center">
              <b-button variant="success" size="sm">
                <feather-icon icon="CheckIcon" /> Mark as Complete
              </b-button>
              <b-button variant="outline-primary" size="sm">
                <feather-icon icon="StarIcon" /> Pin
              </b-button>
            </div> -->
            <div>
              <h4>{{ item.name.name }}</h4>
              <b-badge variant="secondary" class="rounded-pill">Tag</b-badge>
              <b-badge variant="warning" class="ml-1 rounded-pill">
                Priority
                <feather-icon icon="ArrowRightIcon" /> Medium
              </b-badge>

              <b-tabs class="mt-2">
                <b-tab active title="Overview">
                  <h5 class="bold mt-2">Description</h5>
                  <div v-html="item.description"></div>
                </b-tab>
                <b-tab title="File">
                  <h5 class="bold">Files</h5>
                  <label for="dropzone">
                    All Files is supported, max filesize 10Mb.
                  </label>
                  <vue-dropzone
                    id="dropzone"
                    :options="dropzoneOptions"
                    v-on:vdropzone-sending="sendingEvent"
                    v-on:vdropzone-success="successEvent"
                  />

                  <hr />
                  <b-row>
                    <b-col md="4" v-for="file in taskFiles" :key="file.id">
                      <a
                        :href="file.file_name"
                        :download="file.file_name"
                        crossorigin="anonymous"
                      >
                        <b-card :title="file.original_name">
                          <h6>Type: {{ file.type }}</h6>
                          <h6>
                            Size:
                            {{ (file.size / (1024 * 1024)).toFixed(2) }} Mb
                          </h6>
                        </b-card>
                      </a>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Comment">
                  <h5 class="bold">Comments</h5>
                  <quill-editor v-model="comment" :options="editorOption">
                    <div id="comment-toolbar" slot="comment-toolbar">
                      <!-- Add a bold button -->
                      <button class="ql-bold">Bold</button>
                      <button class="ql-italic">Italic</button>

                      <!-- Add font size dropdown -->
                      <select class="ql-size">
                        <option value="small" />
                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                        <option selected />
                        <option value="large" />
                        <option value="huge" />
                      </select>

                      <select class="ql-font">
                        <option selected="selected" />
                        <option value="serif" />
                        <option value="monospace" />
                      </select>

                      <!-- Add subscript and superscript buttons -->
                      <button class="ql-script" value="sub" />
                      <button class="ql-script" value="super" />
                    </div>
                  </quill-editor>
                  <b-button variant="primary" size="sm" class="mt-2">
                    <feather-icon icon="SendIcon" /> Send
                  </b-button>
                </b-tab>
                <b-tab title="Note">
                  <h5 class="bold">Notes</h5>
                  <quill-editor v-model="note" :options="noteOption">
                    <div id="note-toolbar" slot="note-toolbar">
                      <!-- Add a bold button -->
                      <button class="ql-bold">Bold</button>
                      <button class="ql-italic">Italic</button>

                      <!-- Add font size dropdown -->
                      <select class="ql-size">
                        <option value="small" />
                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                        <option selected />
                        <option value="large" />
                        <option value="huge" />
                      </select>

                      <select class="ql-font">
                        <option selected="selected" />
                        <option value="serif" />
                        <option value="monospace" />
                      </select>

                      <!-- Add subscript and superscript buttons -->
                      <button class="ql-script" value="sub" />
                      <button class="ql-script" value="super" />
                    </div>
                  </quill-editor>
                  <b-button variant="primary" size="sm" class="mt-2">
                    <feather-icon icon="SendIcon" /> Send
                  </b-button>
                </b-tab>
                <b-tab title="History"> History </b-tab>
              </b-tabs>
            </div>
          </b-col>
          <b-col md="3" class="right-area">
            <div class="border-b">
              <h6 class="bold">Status</h6>
              <feather-icon icon="CircleIcon" size="18" class="text-primary" />
              {{
                item.status.status == "new"
                  ? "New"
                  : item.status.status == "inprogress"
                  ? "In Progress"
                  : item.status.status == "complete"
                  ? "Completed"
                  : "Canceled"
              }}
            </div>
            <div class="border-b mt-1">
              <h6 class="bold">Assigned To</h6>
              <b-avatar-group size="32px">
                <b-avatar
                  v-for="user in item.assigned_to"
                  :key="user.id"
                  v-b-tooltip.hover
                  class="pull-up"
                  :title="user.name"
                  :src="user.profile_photo_path"
                />
              </b-avatar-group>
            </div>
            <div class="border-b mt-1">
              <h6 class="bold">Assigned By</h6>
              <b-avatar
                :src="item.created_by.profile_photo_path"
                alt="avatar"
              ></b-avatar>
              {{ item.created_by.name }}
            </div>
            <div class="border-b mt-1">
              <h6 class="bold">Start Date</h6>
              <feather-icon icon="CalendarIcon" size="18" />
              {{ item.start_date }}
            </div>
            <div class="border-b mt-1">
              <h6 class="bold">Due Date</h6>
              <feather-icon icon="CalendarIcon" size="18" /> {{ item.due_date }}
            </div>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>

    <b-modal
      v-for="(todo, index) in items"
      :key="index"
      :id="'detail_task_' + todo.id"
      hide-footer
      no-close-on-backdrop
      size="xl"
      :title="`Detail Task - ${todo.name.name}`"
      data-bs-focus="false"
    >
      <form @submit.prevent="update_todo(todo)">
        <div class="row">
          <div class="col-md-9">
            <b-form-group label="To-Do Title">
              <b-form-input
                id="todo_title"
                v-model="todo.name.name"
                placeholder="To-Do Title"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <quill-editor v-model="todo.description" :options="snowOption" />
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Status">
              <b-form-select
                id="todo_status"
                v-model="todo.status"
                :options="statusOptions"
                required
                size="sm"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Priority">
              <b-form-select
                id="todo_priority"
                v-model="todo.priority"
                :options="priorityOptions"
                required
                size="sm"
              ></b-form-select>
            </b-form-group>
            <!-- <b-form-group label="Milestone">
                <b-form-select
                  id="todo_milestone"
                  v-model="todo.milestone"
                  :options="milestoneOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group> -->
            <hr />
            <b-form-group label="Assigneed To">
              <b-form-select
                id="todo_assignee"
                v-model="todo.assigned_to"
                :options="assigneeOptions"
                required
                size="sm"
                multiple
              ></b-form-select>
            </b-form-group>
            <hr />
            <b-form-group label="Start Date">
              <b-form-input
                id="todo_start_date"
                v-model="todo.start_date"
                type="date"
                placeholder="Start Date"
                required
                size="sm"
              />
            </b-form-group>
            <b-form-group label="Due Date">
              <b-form-input
                id="todo_due_date"
                v-model="todo.due_date"
                type="date"
                placeholder="Due Date"
                required
                size="sm"
              />
            </b-form-group>
          </div>
          <div class="col-12 text-right mt-1">
            <b-button
              type="button"
              variant="outline-danger"
              class="mr-1"
              @click="confirmDelete(todo.id)"
            >
              Delete Task
            </b-button>
            <b-button type="submit" variant="primary"> Update Task </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </b-row>
</template>

<style>
.b-sidebar {
  width: calc(100vw - 25rem) !important;
}

.left-area {
  border-right: 1px solid #edeff0;
  padding: 1.5rem 2rem;
  height: 100vh;
}

.right-area {
  border-left: 1px solid #edeff0;
  padding: 1.5rem 1rem;
  height: 100vh;
}

.border-b {
  border-bottom: 1px solid #edeff0;
  padding-bottom: 1em;
}

.header-wrap {
  white-space: nowrap !important;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BSidebar,
  VBToggle,
  BTab,
  BTabs,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    quillEditor,
    vueDropzone: vue2Dropzone,
    ToastificationContent,
    BButton,
    BSidebar,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      projects: [],
      users: [],
      project: "",
      assignedTo: "",
      assignedBy: "",
      dateRange: "",
      status: "",
      task_files: [],

      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "no",
          label: "#",
        },
        { key: "name", label: "ToDo", sortable: true },
        { key: "project", label: "Project", sortable: true },
        // {
        //   key: "profile_photo_url",
        //   label: "Members",
        // },
        { key: "due_date", label: "Due Date", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      status: [
        {
          new: "new",
          inprogress: "inprogress",
          complete: "complete",
          cancel: "cancel",
        },
        {
          new: "light-primary",
          inprogress: "light-warning",
          complete: "light-success",
          cancel: "light-danger",
        },
      ],

      // export excel
      exportFields: {
        "Task Name": "t_name",
        Project: "t_project",
        "Task Members": "t_member",
        "Start Date": "t_start_date",
        Deadline: "t_deadline",
        Status: "t_status",
      },
      exportItems: [],
      statuses: [
        {
          id: 1,
          color: "primary",
          value: "new",
          name: "New",
        },
        {
          id: 2,
          color: "warning",
          value: "progress",
          name: "Inprogress",
        },
        {
          id: 3,
          color: "success",
          value: "complete",
          name: "Completed",
        },
        {
          id: 4,
          color: "danger",
          value: "cancel",
          name: "Canceled",
        },
      ],

      dropzoneOptions: {
        url: "https://sw-api.hracademy.id/api/task-files/create",
        maxFilesize: 10,
        headers: {
          Authorization: "Bearer " + this.getCookie("sw_auth_token"),
        },
        uploadMultiple: true,
        paramName: "file_name",
      },

      editorOption: {
        modules: {
          "comment-toolbar": "#comment-toolbar",
        },
      },
      noteOption: {
        modules: {
          "note-toolbar": "#note-toolbar",
        },
      },
      comment: ``,
      note: ``,

      statusOptions: [
        { value: "new", text: "New" },
        { value: "blocked", text: "Blocked" },
        { value: "inprogress", text: "In Progress" },
        { value: "complete", text: "Complete" },
        { value: "cancel", text: "Cancel" },
      ],
      priorityOptions: [
        { value: "low", text: "Low" },
        { value: "medium", text: "Medium" },
        { value: "high", text: "High" },
      ],
      assigneeOptions: [],
      taskId: 0,
      fStatus: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    taskFiles() {
      return this.task_files.filter((f) => f.task_id == this.taskId);
    },

    sortedUsers() {
      return this.users.sort((a, b) => a.name.localeCompare(b.name));
    },

    sortedTasks() {
      return this.items.sort((a, b) => b.no - a.no);
    },
  },
  mounted() {
    const channel = window.Echo.channel("task");

    channel.listen("TaskCreated", (event) => {
      this.items.push({
        no: this.items.length + 1,
        id: event.task.id,
        name: { name: event.task.name, id: event.task.id },
        project: event.task.project.name,
        start_date: event.task.start_date,
        due_date: event.task.due_date,
        status: {
          status: event.task.status,
          id: event.task.id,
        },
        assigned_to: event.task.assigned_to,
        actions: { id: event.task.id, created_by: event.task.created_by.id },
        created_by: event.task.created_by,
      });
    });

    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    this.fetchData();
    this.get_user();
  },
  methods: {
    handleSuccess(file, response) {
      console.log("File uploaded successfully!", file, response);
      // Do something with the response data here
    },

    setTaskId(id) {
      this.taskId = id;
    },

    sendingEvent(file, xhr, formData) {
      formData.append(
        "user_id",
        JSON.parse(this.getFromStorage("sw_auth_data")).id
      );
      formData.append("task_id", this.taskId);
    },

    successEvent(file, response) {
      response.result.map((item) => {
        this.task_files.push(item);
      });
    },

    get_user() {
      this.callApi({
        url: "/users/getAll",
        method: "GET",
        params: { limit: 100 },
        success: (res) => {
          res.result.data.map((item) => {
            this.assigneeOptions.push({
              value: `${item.id}`,
              text: item.name,
            });

            this.users.push(item);
            this.users = this.users.filter((f) => f.id !== 1 && f.id !== 3);
          });

          if (this.getRole() == "staff") {
            // exclude all, except current user
            this.assigneeOptions = [
              {
                value: JSON.parse(localStorage.getItem("sw_auth_data")).id,
                text: JSON.parse(localStorage.getItem("sw_auth_data")).name,
              },
            ];
          } else {
            // exclude current user
            this.assigneeOptions = this.assigneeOptions.filter(
              (item) => item.value != 1
            );
          }
        },
      });
    },

    hideSidebar(id) {
      // Call the hide method on the sidebar component
      this.$refs[`sidebar_${id}`].hide();
    },
    onRefresh() {
      this.fetchData();
    },

    fetchData(filters = {}) {
      this.$refs["mainCard"].showLoading = true;
      this.items = [];
      this.exportItems = [];
      this.totalRows = 0;

      // filters
      this.callApi({
        url: "/projects/fetch",
        params: filters,
        success: (res) => {
          res.result.map((item) => {
            this.projects.push(item);
          });
        },
      });

      this.callApi({
        method: "GET",
        url: "/tasks/fetch",
        params: filters,
        success: (response) => {
          response.result.forEach((item, index) => {
            this.items.push({
              no: (index += 1),
              id: item.id,
              name: { name: item.name, id: item.id },
              description: item.description,
              project: item.project.name,
              start_date: item.start_date,
              due_date: item.due_date,
              status: {
                status: item.status,
                id: item.id,
              },
              actions: { id: item.id, created_by: item.created_by.id },
              assigned_to: item.assigned_to.slice(0, 5),
              created_by: item.created_by,
            });

            item.task_file.map((item) => {
              this.task_files.push(item);
            });

            // delete items hrcode 1101101
            this.items = this.items.filter((item) => {
              return item.hrcode !== "1101101";
            });

            this.exportItems.push({
              t_name: item.name,
              t_description: item.description,
              t_project: item.project.name,
              t_start_date: item.start_date,
              t_member: item.assigned_to.map((item) => item.name).join(","),
              t_deadline: item.due_date,
              t_status: item.status,
            });
          });
          //
          this.totalRows = this.items.length;

          this.items.sort((a, b) => b.no - a.no);

          // export excel
          this.$refs["mainCard"].showLoading = false;
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to fetch data",
              variant: "error",
            },
          });
          this.$refs["mainCard"].showLoading = false;
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateStatus(status, id) {
      this.callApi({
        method: "POST",
        url: "/tasks/update/" + this.hashid(id),
        data: {
          status: status,
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Update successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to update",
              variant: "error",
            },
          });
        },
      });
    },

    resetFilter() {
      this.project = "";
      this.assignedTo = "";
      this.assignedBy = "";
      this.status = "";
    },
    applyFilter() {
      let params = {};

      if (this.assignedTo != "" && this.assignedTo != null) {
        params.assigned_to = this.hashid(this.assignedTo);
      }

      if (this.assignedBy != "" && this.assignedBy != null) {
        params.created_by = this.hashid(this.assignedBy);
      }

      if (this.project != "" && this.project != null) {
        params.project_id = this.hashid(this.project);
      }

      if (this.fStatus != "" && this.project != null) {
        params.status = this.fStatus;
      }

      this.fetchData(params);
    },
  },
};
</script>
<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.vue-dropzone > .dz-preview .dz-image {
  width: 0%;
  height: 0%;
  background: blue;
}
</style>
